import Head from "next/head";
import { ReactElement } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useQuery } from "react-query";

import {
   SimpleGrid,
   Box,
   Flex,
   Heading,
   Text,
   Divider,
   Stack,
   Icon,
   Link as ChakraLink,
   useColorModeValue,
   useToast,
} from "@chakra-ui/react";

import {
   IoBarChartSharp,
   IoSearchSharp,
} from "react-icons/io5";

import { LoadingPage } from "../components/Loading/LoadingPage";
import { Container } from "../components/Containers/Container";
import { api, apiV2 } from "../services/api";
import { FlexContainer } from "../components/Containers/FlexContainer";
import { Can } from "../components/Can";

interface FeatureProps {
   text: string;
   iconBg: string;
   icon?: ReactElement;
}

interface EventsResponse {
   id: string;
   titulo: string;
   subtitulo: string;
   slug: string;
   dt_evento: Date;
   url_imagem: string;
   vagas: number;
}

interface AxiosResponse {
   events: EventsResponse[];
}

function Feature({ text, icon, iconBg }: FeatureProps) {
   return (
      <Stack direction="row" align="center">
         <Flex
            w={8}
            h={8}
            align="center"
            justify="center"
            rounded="full"
            bg={iconBg}
         >
            {icon}
         </Flex>
         <Text fontWeight={600}>{text}</Text>
      </Stack>
   );
}

function Home() {
   const { getAccessTokenSilently } = useAuth0();
   const toast = useToast();

   const { data } = useQuery<EventsResponse[]>("events", async () => {
      const token = await getAccessTokenSilently();

      const date = new Date();
      const [year, month, day] = new Date(date.setHours(date.getHours() - 3))
         .toISOString()
         .split("T")[0]
         .split("-");

      const { data: axiosData } = await api.get<AxiosResponse>(
         "/marketing/events",
         {
            params: {
               limit: 4,
               cancelled: false,
               after_date: `${day}-${month}-${year}`,
            },
            headers: {
               Authorization: `Bearer ${token}`,
            },
         },
      );

      return axiosData.events.map(event => ({
         id: event.id,
         title: event.titulo,
         subtitle: event.subtitulo,
         slug: event.slug,
         event_date: event.dt_evento,
         image_url: event.url_imagem,
         vacancies: event.vagas,
      }));
   });

   async function handleOpenAwardReport() {
      const token = await getAccessTokenSilently();

      const { data: reportData } = await apiV2.get<{ report_url: string }>(
         "/awards/report",
         {
            headers: {
               Authorization: `Bearer ${token}`,
            },
         },
      );

      if (reportData.report_url) {
         window.open(reportData.report_url, "_blank");
         return;
      }

      toast({
         title: "Nenhum relatório disponível",
         status: "error",
         isClosable: true,
         position: "top-right",
      });
   }

   return (
      <>
         <Head>
            <title>Alta Vista Investimentos</title>
         </Head>
         <Container>
            <FlexContainer>
               <Flex flexDir="column">
                  <Heading fontSize="1.5em" mb="1em">
                     Conteúdos
                  </Heading>
                  <SimpleGrid columns={[1, 3]} spacing={["3", "10"]}>
                     <Box
                        as="a"
                        bg="blue.900"
                        p="5"
                        borderRadius="10"
                        maxWidth="720px"
                        href="https://altavista.tradeinsights.com/alocacao-e-produtos"
                     >
                        <Heading as="h3" fontSize="24" mb="5">
                           Carteira recomendada
                        </Heading>
                        <Text fontSize="16" color="gray.400">
                           Carteiras recomendadas para cada perfil feito pelo
                           time de alocação Alta Vista.
                        </Text>
                     </Box>
                     <Box
                        bg="blue.900"
                        p="5"
                        borderRadius="10"
                        maxWidth="720px"
                        as="a"
                        href="https://altavista.tradeinsights.com/previdencia-1"
                     >
                        <Heading as="h3" fontSize="24" mb="5">
                           Previdência
                        </Heading>
                        <Text fontSize="16" color="gray.400">
                           Carteiras recomendadas de previdência, ache o extrato
                           para ajudar nas portabilidade e muito mais.
                        </Text>
                     </Box>
                     <Box
                        bg="blue.900"
                        p="5"
                        borderRadius="10"
                        maxWidth="720px"
                        as="a"
                        href="https://altavista.tradeinsights.com/cenario"
                     >
                        <Heading as="h3" fontSize="24" mb="5">
                           Carta Comitê
                        </Heading>
                        <Text fontSize="16" color="gray.400">
                           Veja a carta mensal do comitê e encontre materiais da
                           carta para que possa apresentar aos seus clientes.
                        </Text>
                     </Box>
                     <Box
                        bg="blue.900"
                        p="5"
                        borderRadius="10"
                        maxWidth="720px"
                        as="a"
                        href="https://altavista.tradeinsights.com/top-fundos-2/page-top-fundos"
                     >
                        <Heading as="h3" fontSize="24" mb="5">
                           Carteira de Fundos
                        </Heading>
                        <Text fontSize="16" color="gray.400">
                           Veja a Lista de Top fundos da Alta Vista.
                        </Text>
                     </Box>
                     <Can roles={["Master", "Awards"]}>
                        <Box
                           bg="blue.900"
                           p="5"
                           borderRadius="10"
                           maxWidth="720px"
                           _hover={{
                              cursor: "pointer",
                           }}
                           onClick={handleOpenAwardReport}
                        >
                           <Heading as="h3" fontSize="24" mb="5">
                              Relatório do Assessor
                           </Heading>
                           <Text fontSize="16" color="gray.400">
                              Veja aqui o seu relatório Awards.
                           </Text>
                        </Box>
                     </Can>
                     <Box
                        bg="blue.900"
                        p="5"
                        borderRadius="10"
                        maxWidth="720px"
                        as="a"
                        href="/filiais"
                     >
                        <Heading as="h3" fontSize="24" mb="5">
                           Reserva de salas
                        </Heading>
                        <Text fontSize="16" color="gray.400">
                           Reserva de sala para reuniões.
                        </Text>
                     </Box>
                  </SimpleGrid>

                  <Divider mb="2em" mt="2em" />

                  <Heading fontSize="1.5em" mb="1em">
                     Links de apoio
                  </Heading>
                  <SimpleGrid columns={[1, 3]} spacing="10">
                     <Flex justifyContent="center" alignItems="center">
                        <Feature
                           icon={
                              <Icon
                                 as={IoBarChartSharp}
                                 color="yellow.500"
                                 w={5}
                                 h={5}
                              />
                           }
                           iconBg={useColorModeValue(
                              "yellow.100",
                              "yellow.900",
                           )}
                        />
                        <ChakraLink
                           target="_blank"
                           py="1"
                           fontWeight="bold"
                           href="https://altavista.tradeinsights.com/marketing"
                        >
                           Materiais de Apoio - Marketing
                        </ChakraLink>
                     </Flex>
                     <Flex justifyContent="center" alignItems="center">
                        <Feature
                           icon={
                              <Icon
                                 as={IoSearchSharp}
                                 color="purple.500"
                                 w={5}
                                 h={5}
                              />
                           }
                           iconBg={useColorModeValue(
                              "purple.100",
                              "purple.900",
                           )}
                        />
                        <ChakraLink
                           target="_blank"
                           py="1"
                           fontWeight="bold"
                           href="https://app.hubspot.com/contacts/21698044/objects/0-5/views/all/board"
                        >
                           Diagnóstico de carteira
                        </ChakraLink>
                     </Flex>
                  </SimpleGrid>
               </Flex>
            </FlexContainer>
         </Container>
      </>
   );
}

export default withAuthenticationRequired(Home, {
   onRedirecting: () => <LoadingPage />,
});
